import * as React from 'react'
import loadable from '@loadable/component'

import { useTheme } from '@thg-commerce/gravity-elements/theme'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import {
  mq,
  spacing,
  styled,
  Text,
  TextStyling,
} from '@thg-commerce/gravity-theme'

import { BackwardsNavigation } from '../BackwardsNavigation'
import Button from '../Button'

export interface BreadcrumbItem {
  text: string
  url: string
}

export interface BreadcrumbsProps {
  items: BreadcrumbItem[]
  breadcrumbLabel: string
  showBackButton?: boolean
  backButton?: {
    text: string
    goBack: () => void
  }
  enableHomePageBreadcrumb?: boolean
  capitalizeText?: boolean
  styleOverride?: {
    paddingBottom?: number
    overflow?: string
  }
  isMobile?: boolean
  ellipsis: {
    enabled: boolean
    minNumberOfItems: number
    hideActiveLink: boolean
  }
}

const BreadcrumbLink = styled.a<{
  capitalizeText?: boolean
  textStyling?: TextStyling
  textDecoration?: string
}>`
  ${(props) =>
    Text(
      props.textStyling.entry || 'small',
      props.textStyling.style || 'default',
    )};
  margin: 0 ${spacing(0.5)};
  padding: 0 ${spacing(0.25)};
  color: ${(props) => props.theme.colors.palette.greys.darker};
  border: 2px solid transparent;
  ${(props) => props.capitalizeText && ` text-transform: capitalize`};
  text-wrap: nowrap;
  text-decoration: ${(props) => props.textDecoration || 'underline'};
  &:focus {
    border-color: ${(props) => props.theme.colors.palette.greys.darker};
    outline: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    &:hover {
      text-decoration: none;
    }
  }
`

const ActiveBreadcrumb = styled.span<{
  capitalizeText?: boolean
  hideActive?: boolean
  textStyling?: TextStyling
}>`
  ${(props) =>
    Text(
      props.textStyling?.entry || 'small',
      props.textStyling?.style || 'alternate',
    )};
  color: ${(props) => props.theme.colors.palette.greys.dark};
  text-decoration: none;
  margin: 0 ${spacing(1)};
  display: ${(props) => (props.hideActive ? 'none' : 'block')};
  ${(props) => props.capitalizeText && `  text-transform: capitalize;`}
`

const ChevronLeft = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronLeft'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const StyledChevronLeft = styled(ChevronLeft)`
  width: 24px;
  height: 24px;
  path {
    fill: ${(props) => props.theme.colors.palette.greys.darker};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    transform: rotate(180deg);
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    transform: rotate(180deg);
  }
`

const BackBreadcrumbButtonWrapper = styled.div`
  div:first-child {
    padding: 0 ${spacing(2)} 0 ${spacing(1)};

    ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
      padding: 0 ${spacing(2)};
    }
  }
`

const BreadcrumbsWrapper = styled.ol<{
  overflow?: string
}>`
  display: flex;
  flex-wrap: nowrap;
  overflow: ${(props) => props.overflow ?? 'hidden'};
  white-space: nowrap;
`

const BreadcrumbItem = styled.li<{ display?: boolean }>`
  margin-top: 0;
  flex-direction: row-reverse;
  display: ${(props) => (props.display ? 'flex' : 'none')};

  &:first-child {
    display: flex;
  }
  &:last-child {
    display: flex;
    align-items: center;
    overflow: hidden;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: flex;
  }
`

const EllipsisButton = styled(Button)`
  ${Text('small', 'default')};
  padding: 0;
  text-decoration: none;
`

const PrefetchBreadcrumbLink = withPrefetch('href', BreadcrumbLink)

export const Breadcrumbs = React.memo((props: BreadcrumbsProps) => {
  const [displayEllipsis, setDisplayEllipsis] = React.useState<boolean>(
    props.items.length > props.ellipsis.minNumberOfItems,
  )
  const [breadcrumbsList, setBreadcrumbsList] = React.useState<
    BreadcrumbItem[]
  >([])

  const theme = useTheme()
  const ellipsisBreadCrumbs =
    props.items.length > 3 && props.ellipsis.hideActiveLink
      ? props.items.slice(-3)
      : props.items.slice(-2)

  React.useEffect(() => {
    setBreadcrumbsList(
      props.ellipsis.enabled && props.isMobile && displayEllipsis
        ? ellipsisBreadCrumbs
        : props.items,
    )
  }, [
    displayEllipsis,
    props.items,
    props.isMobile,
    ellipsisBreadCrumbs,
    props.ellipsis.enabled,
  ])

  const breadcrumbItems = breadcrumbsList.map((item, index) => {
    const displayIcon =
      props.ellipsis.hideActiveLink && index === breadcrumbsList.length - 2
        ? 'none'
        : 'block'

    return index !== breadcrumbsList.length - 1 ? (
      <BreadcrumbItem
        key={index}
        data-testid={`breadcrumb-item-${index}`}
        display={props.ellipsis.enabled && props.isMobile}
      >
        {theme.elements.breadCrumbs.chevronIcon.right.svgPath ? (
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={theme.elements.breadCrumbs.chevronIcon.viewBox}
            width={theme.elements.breadCrumbs.chevronIcon.width}
            height={theme.elements.breadCrumbs.chevronIcon.height}
            aria-hidden="true"
            focusable="false"
            style={{
              display: displayIcon || 'block',
            }}
          >
            <path
              d={theme.elements.breadCrumbs.chevronIcon.right.svgPath}
              fillRule="evenodd"
            />
          </SvgIcon>
        ) : (
          <StyledChevronLeft aria-hidden="true" focusable="false" />
        )}
        <PrefetchBreadcrumbLink
          href={item.url}
          capitalizeText={props.capitalizeText}
          textStyling={theme.elements.breadCrumbs.linkStyling}
          textDecoration={theme.elements.breadCrumbs.linkStyling.textDecoration}
        >
          {item.text}
        </PrefetchBreadcrumbLink>
      </BreadcrumbItem>
    ) : (
      <BreadcrumbItem
        key={index}
        data-testid={`breadcrumb-item-${index}`}
        display={props.ellipsis.enabled && props.isMobile}
      >
        {props.enableHomePageBreadcrumb && breadcrumbsList.length === 1 ? (
          <PrefetchBreadcrumbLink
            href={item.url}
            capitalizeText={props.capitalizeText}
          >
            {item.text}
          </PrefetchBreadcrumbLink>
        ) : (
          <ActiveBreadcrumb
            aria-current="page"
            data-testid="active-breadcrumb"
            capitalizeText={props.capitalizeText}
            {...(props.ellipsis.hideActiveLink && {
              hideActive: props.ellipsis.hideActiveLink,
            })}
            textStyling={theme.elements.breadCrumbs.activeLinkStyling}
          >
            {item.text}
          </ActiveBreadcrumb>
        )}
      </BreadcrumbItem>
    )
  })

  return breadcrumbsList.length > 0 ? (
    <div>
      <nav
        aria-label={props.breadcrumbLabel}
        style={{
          display: 'flex',
          width: '100%',
          paddingBottom:
            (props.ellipsis.enabled &&
              props.isMobile &&
              spacing(props.styleOverride?.paddingBottom || 0)) ||
            0,
          paddingLeft:
            (props.ellipsis.enabled &&
              !props.showBackButton &&
              props.isMobile &&
              spacing(props.styleOverride?.paddingBottom || 0)) ||
            0,
        }}
      >
        {props.showBackButton && props.backButton && (
          <BackBreadcrumbButtonWrapper>
            <BackwardsNavigation
              onClick={props.backButton.goBack}
              text={props.backButton.text}
            />
          </BackBreadcrumbButtonWrapper>
        )}
        {props.ellipsis.enabled && props.isMobile && displayEllipsis && (
          <div style={{ display: 'flex' }}>
            <EllipsisButton
              emphasis="low"
              onClick={() => setDisplayEllipsis(false)}
            >
              ...
            </EllipsisButton>
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.breadCrumbs.chevronIcon.viewBox}
              width={theme.elements.breadCrumbs.chevronIcon.width}
              height={theme.elements.breadCrumbs.chevronIcon.height}
            >
              <path
                d={theme.elements.breadCrumbs.chevronIcon.right.svgPath}
                fillRule="evenodd"
              />
            </SvgIcon>
          </div>
        )}
        <BreadcrumbsWrapper
          {...(props.ellipsis.enabled &&
            props.isMobile && {
              overflow: props.styleOverride?.overflow,
            })}
        >
          {breadcrumbItems}
        </BreadcrumbsWrapper>
      </nav>
    </div>
  ) : (
    <React.Fragment />
  )
})
