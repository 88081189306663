import * as React from 'react'
import getConfig from 'next/config'

import sentry from '../utils/sentry'
import { EnterpriseContext } from '../EnterpriseContext'

const { captureExceptionComponentLevel } = sentry()

export class ErrorBoundary extends React.Component<{}, { error: any }> {
  static contextType = EnterpriseContext

  constructor(props: any) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { publicRuntimeConfig } = getConfig()
    const { logger, brand, subsite } = this.context

    if (publicRuntimeConfig['IS_PRODUCTION']) {
      captureExceptionComponentLevel(error, brand, subsite)
    }

    logger.error(error.message, {
      stack: errorInfo.componentStack,
    })

    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      return <h1 data-testid="error-message">An error occured</h1>
    }
    return this.props.children
  }
}
