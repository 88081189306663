import * as React from 'react'
import { i18n, Routes, useSessionSettings } from '@thg-commerce/enterprise-core'
import { FullHeaderLayout } from '@thg-commerce/enterprise-core/src/Layouts'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { ComponentWidgetRenderer } from '@thg-commerce/enterprise-widgets'
import { ComponentName } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/ComponentWidgets'

const AnchoredContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.palette.greys.white};
`

const AnchoredStyledTitle = styled.h1`
  margin: ${spacing(7)} auto ${spacing(1)} auto;
  ${Text('large2', 'default')}
`

const StyledDescription = styled.div`
  margin-bottom: ${spacing(4)};
  padding: ${spacing(2)};
  max-width: 560px;
  text-align: center;
  ${Text('bodyText', 'default')}
`

const AnchoredReturnToHomeButton = styled(Button)`
  max-width: 95%;
  width: 345px;
  margin-bottom: ${spacing(10)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: 425px;
  }
`
const CategoryPanelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${spacing(7)};
`

const CategoryPanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  margin-bottom: ${spacing(7)};
  & p {
    ${Text('bodyText', 'alternate')}
  }
  & img {
    padding: 7px;
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    width: 50%;
    margin-bottom: 36px;
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 33%;
  }
`
interface Category {
  name: string
  src: string
  alt: string
}

const CustomError = ({ statusCode }) => {
  // TODO: Placeholder for Category item implementation when the Horizon work is complete.
  const [categories] = React.useState<Category[]>([])
  const sessionSettings = useSessionSettings()
  const { currency, shippingDestination } = sessionSettings

  const errorI18nText = {
    title: '',
    description: '',
    buttonDescription: i18n('pageunavailable.enterprise.button.description'),
  }

  if (statusCode === 404) {
    errorI18nText.title = RemoveFullStops(
      i18n('pageunavailable.pagenotfound.text'),
    )
    errorI18nText.description = i18n(
      'pageunavailable.pagenotfound.details.text',
    )
  } else {
    errorI18nText.title = RemoveFullStops(
      i18n('pageunavailable.servererror.text'),
    )
    errorI18nText.description = i18n('pageunavailable.servererror.details.text')
  }

  return (
    <AnchoredContentWrapper>
      <AnchoredStyledTitle>{errorI18nText.title}</AnchoredStyledTitle>
      <StyledDescription>{errorI18nText.description}</StyledDescription>

      {categories.length > 0 && <CategoryPanels categories={categories} />}
      <AnchoredReturnToHomeButton
        emphasis="medium"
        renderedAs="a"
        href={Routes.HomePage}
      >
        {errorI18nText.buttonDescription}
      </AnchoredReturnToHomeButton>
      {statusCode === 404 && (
        <ComponentWidgetRenderer
          componentName={ComponentName.PAGE_UNAVAILABLE}
          currency={currency}
          shippingDestination={shippingDestination}
        />
      )}
    </AnchoredContentWrapper>
  )
}

const CategoryPanels = (props: { categories: Category[] }) => {
  const { categories } = props
  return (
    <CategoryPanelsWrapper>
      {categories.map((category, index) => {
        return (
          <CategoryPanel key={index}>
            <img src={category.src} alt={category.alt} />
            <p>Category {index + 1}</p>
          </CategoryPanel>
        )
      })}
    </CategoryPanelsWrapper>
  )
}

const RemoveFullStops = (input: string) => {
  return input.replace(/\./g, '')
}

// @ts-ignore
CustomError.Layout = FullHeaderLayout

CustomError.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default CustomError
