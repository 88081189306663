import * as React from 'react'
import Head from 'next/head'

import {
  getSafeUrlParametersFromWindow,
  i18n,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import { spacing, styled } from '@thg-commerce/enterprise-theme'
import { Breadcrumbs } from '@thg-commerce/gravity-elements'
import { padding } from '@thg-commerce/gravity-theme'

export const BreadcrumbsRendererWrapper = styled.div`
  display: flex;
  height: ${(props) => props.theme.elements?.breadCrumbs?.height};
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: ${spacing(3)} auto 0 auto;
  ${(props) => padding(props.theme.elements.breadCrumbs.padding)}
`

export type BreadcrumbsRendererProps = {
  breadcrumbs: {
    text: string
    url: string
  }[]
  schemaItems: {
    '@type': 'ListItem' | string
    position: number
    name: string
    item: string
  }[]
  breadcrumbLabel?: string
  enableBackButton?: boolean
  className?: string
  enableBackToSearchButton?: boolean
  originUrl?: string
  capitalizeText?: boolean
  styleOverride?: {
    paddingBottom?: number
    overflow?: string
  }
  isMobile?: boolean
  ellipsis?: {
    enabled?: boolean
    minNumberOfItems?: number
    hideActiveLink?: boolean
  }
}

export const BreadcrumbsRenderer = ({
  breadcrumbs,
  schemaItems,
  enableBackButton,
  className,
  originUrl,
  styleOverride,
  ellipsis = {},
  isMobile,
  breadcrumbLabel,
  capitalizeText,
}: BreadcrumbsRendererProps) => {
  const {
    enableBackToSearchWithinBreadCrumbs,
    enableHomePageBreadcrumb,
  } = useSiteConfig()
  const backButtonI18nText = i18n('general.back.text')
  const breadcrumbsAriaLabel = i18n('general.breadcrumbs.arialabel')

  const [showBackButton, setShowBackButton] = React.useState<boolean>(false)
  const [backButtonHref, setBackButtonHref] = React.useState('')
  const referrerUrl = typeof document !== 'undefined' ? document.referrer : ''
  const currentUrl = typeof window !== 'undefined' ? window.location.href : ''
  const {
    enabled = false,
    minNumberOfItems = 3,
    hideActiveLink = false,
  } = ellipsis

  React.useEffect(() => {
    const searchParameter = getSafeUrlParametersFromWindow(window, 'search')[
      'search'
    ]

    if (enableBackButton) {
      const sessionStorage = window.sessionStorage
      if (!sessionStorage) {
        return
      }

      const performanceNavigationTiming: PerformanceEntry & {
        type?: string
      } = window.performance.getEntriesByType('navigation')[0]

      if (!performanceNavigationTiming) {
        return
      }

      const initialPreviousCurrentPages = sessionStorage.getItem(
        'previousCurrentPages',
      )
        ? JSON.parse(sessionStorage.getItem('previousCurrentPages') || '')
        : ''

      const previousCurrentPages = {
        previous: initialPreviousCurrentPages.current,
        current: window.location.pathname,
      }

      sessionStorage.setItem(
        'previousCurrentPages',
        JSON.stringify(previousCurrentPages),
      )

      if (!sessionStorage.getItem('firstVisitedPage')) {
        // firstVisitedPage is saved in the storage because the back button should not be displayed
        // when navigating with the back button until the first page visited
        sessionStorage.setItem('firstVisitedPage', window.location.pathname)
        // firstVisitedPageCount is saved in the storage because the back button should be displayed
        // when navigating to the first page multiple times after the initial page visit
        sessionStorage.setItem('firstVisitedPageCount', '0')
      }

      const firstVisitedPage = sessionStorage.getItem('firstVisitedPage') || ''

      let firstVisitedPageCount =
        Number(sessionStorage.getItem('firstVisitedPageCount')) || 0

      // when navigating to the first page again (not by the back button), the count for the first page
      // needs to be increased to be able to determine if we display the back button

      // window.location.pathname !== previousCurrentPages.previous - when navigating to the first page again twice,
      // the counter does not need to be increased as the history is not changed
      if (
        performanceNavigationTiming.type === 'navigate' &&
        window.location.pathname === firstVisitedPage &&
        window.location.pathname !== previousCurrentPages.previous
      ) {
        firstVisitedPageCount += 1
      }

      // when navigating to the first page again (not by the back button), then the back button is clicked,
      // the counter for the first page needs to be decreased
      // the counter needs to be bigger than 1 in this case, as this means the first page was visited again
      if (
        performanceNavigationTiming.type === 'back_forward' &&
        window.location.pathname === firstVisitedPage &&
        firstVisitedPageCount > 1
      ) {
        firstVisitedPageCount -= 1
      }

      sessionStorage.setItem(
        'firstVisitedPageCount',
        firstVisitedPageCount.toString(),
      )

      if (
        currentUrl.includes('autocomplete=productsuggestion') &&
        currentUrl.includes('search=') &&
        searchParameter
      ) {
        setBackButtonHref(
          `${originUrl}/elysium.search?search=${searchParameter}`,
        )
      }

      // the back button is displayed when the current page is different from the first visited page or
      // when the first visited page is the same as the current page, but was accessed more than once
      // its also displayed when enableBackToSearchWithinBreadCrumbs is enabled and the url has the correct
      // parameters are available
      setShowBackButton(
        firstVisitedPageCount > 1 ||
          window.location.pathname !== firstVisitedPage ||
          referrerUrl.includes('elysium.search') ||
          ((enableBackToSearchWithinBreadCrumbs || false) &&
            currentUrl.includes('autocomplete=productsuggestion') &&
            currentUrl.includes('search=')),
      )
    }
  }, [
    currentUrl,
    enableBackButton,
    enableBackToSearchWithinBreadCrumbs,
    originUrl,
    referrerUrl,
  ])

  if (!breadcrumbs || (!enableHomePageBreadcrumb && breadcrumbs.length <= 1)) {
    return null
  }

  const breadcrumbsSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: schemaItems,
  }

  const backButton = {
    text: backButtonI18nText,
    goBack: () => {
      enableBackToSearchWithinBreadCrumbs && !!backButtonHref
        ? window.location.assign(backButtonHref)
        : window.history.back()
    },
  }

  return (
    <BreadcrumbsRendererWrapper data-testid="breadcrumbs" className={className}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbsSchema),
          }}
        />
      </Head>
      <Breadcrumbs
        enableHomePageBreadcrumb={enableHomePageBreadcrumb}
        breadcrumbLabel={breadcrumbLabel || breadcrumbsAriaLabel}
        items={breadcrumbs}
        backButton={backButton}
        showBackButton={showBackButton}
        capitalizeText={capitalizeText}
        styleOverride={styleOverride}
        ellipsis={{
          hideActiveLink,
          enabled,
          minNumberOfItems,
        }}
        isMobile={isMobile}
      />
    </BreadcrumbsRendererWrapper>
  )
}
