import * as React from 'react'
import getConfig from 'next/config'
import parse from 'html-react-parser'

import { useSiteConfig } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/hooks'
import { NextConfig } from '@thg-commerce/enterprise-config'

import * as server from './server/server'
import { ESIComponent } from './types'

const nextConfig = getConfig() as NextConfig

declare global {
  interface Window {
    __REACT_ESI__: { [s: string]: object }
  }
}

interface IWithESIProps {
  esi?: {
    attrs?: {
      [key: string]: string | null
    }
  }
}

interface IWebpackProcess extends NodeJS.Process {
  browser: boolean
}

const initialChildProps = (initialChildProps, fragmentID) => {
  if (
    (process as IWebpackProcess).browser &&
    window.__REACT_ESI__ &&
    window.__REACT_ESI__[fragmentID]
  ) {
    return {
      ...window.__REACT_ESI__[fragmentID],
      ...initialChildProps,
    }
  }

  return { ...initialChildProps }
}

export function withESI<Props, InitialProps>(
  WrappedComponent: ESIComponent<Props, InitialProps>,
  fragmentID: string,
  configKey: string = '',
  envVar: string = '',
): React.FunctionComponent<IWithESIProps & Props & InitialProps> {
  return (props) => {
    const { esi = {}, ...propsChildProps } = props
    const childProps = initialChildProps(propsChildProps, fragmentID)
    const siteConfig = useSiteConfig()
    const esiEnabledEnv = nextConfig.publicRuntimeConfig[envVar] === true
    const esiEnabledSiteConfig = siteConfig[configKey] === true
    const checkESIEnabled = configKey && envVar

    const enableESI = checkESIEnabled
      ? esiEnabledSiteConfig && esiEnabledEnv
      : true

    if (!enableESI || (process as IWebpackProcess).browser) {
      return (
        <WrappedComponent
          {...(childProps as JSX.IntrinsicAttributes & Props)}
        />
      )
    }

    const esiComponent = server.createIncludeElement(fragmentID, props, esi)

    return parse(esiComponent) as React.ReactElement
  }
}
