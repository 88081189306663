// https://github.com/dunglas/react-esi/blob/main/src/server.tsx
// Copied to support styled components collection

import * as React from 'react'

import crypto from 'crypto'

import { Request, Response } from 'express'
import { renderToNodeStream } from 'react-dom/server'
import { Readable } from 'stream'
import { ServerStyleSheet } from 'styled-components'

export const path = process.env.REACT_ESI_PATH || '/_fragment'
const secret =
  process.env.REACT_ESI_SECRET || crypto.randomBytes(64).toString('hex')

/**
 * Signs the ESI URL with a secret key using the HMAC-SHA256 algorithm.
 */
function sign(url: URL) {
  const hmac = crypto.createHmac('sha256', secret)
  hmac.update(url.pathname + url.search)
  return hmac.digest('hex')
}

/**
 * Escapes ESI attributes.
 *
 * Adapted from https://stackoverflow.com/a/27979933/1352334 (hgoebl)
 */
function escapeAttr(attr: string): string {
  return attr.replace(/[<>&'"]/g, (c) => {
    switch (c) {
      case '<':
        return '&lt;'
      case '>':
        return '&gt;'
      case '&':
        return '&amp;'
      case "'":
        return '&apos;'
      default:
        return '&quot;'
    }
  })
}

interface IEsiAttrs {
  src?: string
  alt?: string
  onerror?: string
}

interface IEsiProps {
  attrs?: IEsiAttrs
}

/**
 * Creates the <esi:include> tag.
 */
export const createIncludeElement = (
  fragmentID: string,
  props: object,
  esi: IEsiProps,
) => {
  const esiAt = esi.attrs || {}

  const url = new URL(path, 'http://example.com')
  url.searchParams.append('fragment', fragmentID)
  url.searchParams.append('props', JSON.stringify(props))
  url.searchParams.append('sign', sign(url))

  esiAt.src = url.pathname + url.search
  let attrs = ''
  Object.entries(esiAt).forEach(
    ([key, value]) => (attrs += ` ${key}="${value ? escapeAttr(value) : ''}"`),
  )

  return `<esi:include${attrs} />`
}

type FragmentResolver = (
  fragmentID: string,
  props: object,
  req: Request,
  res: Response,
) => React.ComponentType<any>

/**
 * Checks the signature, renders the given fragment as HTML and injects the initial props in a <script> tag.
 */
export async function serveFragment(
  req: Request,
  res: Response,
  resolve: FragmentResolver,
) {
  const url = new URL(req.url, 'http://example.com')
  const expectedSign = url.searchParams.get('sign')

  url.searchParams.delete('sign')
  if (sign(url) !== expectedSign) {
    res.status(400)
    res.send('Bad signature')
    return
  }

  const rawProps = url.searchParams.get('props')
  const props = rawProps ? JSON.parse(rawProps) : {}

  const fragmentID = url.searchParams.get('fragment') || ''

  const Component = resolve(fragmentID, props, req, res)
  const { esi, ...baseChildProps } = props

  const childProps = (Component as any).getInitialProps
    ? await (Component as any).getInitialProps({
        req,
        res,
        props: baseChildProps,
      })
    : {}

  const { serverProps, ...browserProps } = childProps
  const encodedProps = JSON.stringify(browserProps).replace(/</g, '\\u003c')

  const script = `<script>window.__REACT_ESI__ = window.__REACT_ESI__ || {}; window.__REACT_ESI__['${fragmentID}'] = ${encodedProps};document.currentScript.remove();</script>`
  const scriptStream = Readable.from(script)
  scriptStream.pipe(res, { end: false })

  const sheet = new ServerStyleSheet()
  const jsx = sheet.collectStyles(
    <Component {...serverProps} {...browserProps} {...baseChildProps} />,
  )
  const stream = sheet.interleaveWithNodeStream(renderToNodeStream(jsx))

  stream.pipe(res)
}
