import * as React from 'react'

import { Router } from 'next/router'

import { spacing, mq, styled, css } from '@thg-commerce/enterprise-theme'
import { Grid, GridProps } from '@thg-commerce/gravity-system'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'

import { EnterpriseContext, useTransmit } from '../../'
import { ErrorBoundary } from '../ErrorBoundary'
import { PageType } from '../App/types'
import { useBasket } from '../Basket/hooks/useBasketId'

interface PageWrapperProps {
  responsiveMargin?: boolean
  className?: string
  children?: React.ReactNode
  enableMaxWidth?: boolean
  sendPageVisit?: boolean
  compactMargin?: boolean
}

const eventTypeMap = {
  [PageType.HOME_PAGE]: 'homepage',
  [PageType.BASKET]: 'basket_visit',
  [PageType.PRODUCT]: 'product_visit',
  [PageType.SEARCH]: 'search',
  [PageType.PRODUCT_LIST]: 'product_list_visit',
}

const StyledGrid = styled(Grid)<PageWrapperProps>`
  margin: auto;
  width: 100%;
  ${(props) =>
    props.enableMaxWidth && `max-width: ${props.theme.grid.maxWidth}px`};
  margin-top: ${(props) => (props.compactMargin ? spacing(0) : spacing(3))};
  margin-bottom: ${spacing(0)};

  ${(props) =>
    props.responsiveMargin &&
    !props.compactMargin &&
    css`
      ${mq(props.theme.breakpointUtils.map, 'sm')} {
        margin-top: ${spacing(5)};
        margin-bottom: ${spacing(6)};
      }
    `}
`

export const PageWrapper = (props: PageWrapperProps & GridProps) => {
  const transmit = useTransmit()
  const EnterpriseCtx = React.useContext(EnterpriseContext)
  const normaliseBackendEvent = useBackendEventNormaliser()
  const { basket } = useBasket()
  const [loaded, setLoaded] = React.useState(false)
  const pageLoadedRef = React.useRef(false)

  const isRouteChanged = React.useCallback(() => {
    if (typeof window === 'undefined') {
      return false
    }

    return Boolean(
      window.performance.getEntriesByName('pageChangeFinish')[0] &&
        window.performance.getEntriesByName('pageChangeStart')[0],
    )
  }, [])

  const transmitPageVisit = () => {
    if (!props.sendPageVisit || !isRouteChanged()) {
      return
    }
    EnterpriseCtx.updateHistory()

    const requestObject = {
      start_timestamp:
        window.performance.timeOrigin +
        window.performance.getEntriesByName('pageChangeStart')[0].startTime,
      url: window.location.href,
      duration_ms:
        window.performance.getEntriesByName('pageChangeFinish')[0].startTime -
        window.performance.getEntriesByName('pageChangeStart')[0].startTime,
    }

    const eventType =
      EnterpriseCtx.pageType && eventTypeMap[EnterpriseCtx.pageType]
        ? eventTypeMap[EnterpriseCtx.pageType]
        : 'page_visit'

    transmit({
      type: 'page_visit',
      attributes: {
        route: window.location.pathname,
      },
      payload: normaliseBackendEvent({
        basket,
        requestData: requestObject,
        eventData: {
          type: eventType,
        },
      }),
    })
  }

  React.useEffect(() => {
    const handleRouteChangeStart = () => {
      window.performance.clearMarks()
      window.performance.mark('pageChangeStart')
    }
    const handleRouteChangeFinish = () => {
      window.performance.mark('pageChangeFinish')
      transmitPageVisit()
    }

    Router.events.on('routeChangeStart', handleRouteChangeStart)
    Router.events.on('routeChangeComplete', handleRouteChangeFinish)
    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart)
      Router.events.off('routeChangeComplete', handleRouteChangeFinish)
    }
  }, [])

  React.useEffect(() => {
    const handleLoad = () => {
      setLoaded(true)
    }

    const pageAlreadyLoaded =
      document.readyState === 'complete' &&
      window.performance.getEntriesByType('navigation').length > 0 &&
      window.performance.getEntriesByType('navigation')[0]?.loadEventStart !== 0
    if (pageAlreadyLoaded) {
      setLoaded(true)
    } else {
      window.addEventListener('load', handleLoad)
    }
    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [])

  React.useEffect(() => {
    const transmitPageVisits =
      EnterpriseCtx.requestConfig.enableBrowserPageVisits && props.sendPageVisit
    const softNavigation =
      window.performance.getEntriesByName('pageChangeStart').length > 0

    if (
      !softNavigation &&
      loaded &&
      !pageLoadedRef.current &&
      transmitPageVisits
    ) {
      EnterpriseCtx.updateHistory()

      const requestObject = {
        start_timestamp: window.performance.timeOrigin,
        url: window.location.href,
        duration_ms: window.performance.getEntriesByType('navigation')[0]
          .duration,
      }

      const eventType =
        EnterpriseCtx.pageType && eventTypeMap[EnterpriseCtx.pageType]
          ? eventTypeMap[EnterpriseCtx.pageType]
          : 'page_visit'

      transmit({
        type: 'page_visit',
        payload: normaliseBackendEvent({
          basket,
          requestData: requestObject,
          eventData: {
            type: eventType,
          },
        }),
      })

      pageLoadedRef.current = true
    }
  }, [
    loaded,
    props.sendPageVisit,
    EnterpriseCtx.requestConfig.enableBrowserPageVisits,
  ])

  const {
    responsiveMargin = false,
    enableMaxWidth = true,
    children,
    ...rest
  } = props
  return (
    <ErrorBoundary>
      <StyledGrid
        {...rest}
        responsiveMargin={responsiveMargin}
        className={props.className}
        enableMaxWidth={enableMaxWidth}
      >
        {children}
      </StyledGrid>
    </ErrorBoundary>
  )
}
